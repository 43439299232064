import React, { useState, useEffect } from "react";
import "./footer.css";
import { CertifiedTokenSvg, CetifiedToken, socialLinks } from "../../Assets";
import { NavLink } from "react-router-dom";

const Footer = ({ visible }) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [isActive, setIsActive] = useState(0);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [navScroll, setNavScroll] = useState();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsScrolling(true);
        setToggleMenu(false);
      } else if (window.scrollY === 0) {
        setIsScrolling(false);
      }
    });
  });
  //-------

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      const sectionOffsets = {
        home: document.getElementById("home"),
        about__Us: document.getElementById("about__Us"),
        mission__vision: document.getElementById("mission__vision"),
        distribution: document.getElementById("distribution"),
        logistics: document.getElementById("logistics"),
        consulting: document.getElementById("consulting"),
        contact__us: document.getElementById("contact__us"),
      };

      let activeSection;

      Object.entries(sectionOffsets).forEach(([sectionId, offset]) => {
        if (scrollPosition >= offset - window.innerHeight / 3) {
          activeSection = sectionId;
        }
      });
      setNavScroll(scrollPosition);

      setIsActive(activeSection);
      setIsScrolling(scrollPosition > 0);

      setToggleMenu(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //-------
  function scrolldiv(scroll, offset) {
    var elem = document.getElementById(scroll);
    if (elem) {
      window.scrollTo({
        top: elem.offsetTop - offset,
        behavior: "smooth",
      });
    }
  }

  return (
    <>
      <div className="footer_main">
        <div className="footer_box">
          <div className="main_container">
            <div className="sub__container1">
              {/* <div className="sub__container1_child"> */}
              <div className="sub__container1_child1">
                <NavLink
                  className="footer_social_links_container"
                  to="#home"
                  onClick={() => {
                    setIsActive("home");
                    scrolldiv("home", 0);
                  }}>
                  Home
                </NavLink>
                <NavLink
                  className="footer_social_links_container"
                  to="#about__Us"
                  onClick={() => {
                    // debugger;
                    setIsActive("abput__Us");
                    scrolldiv("about__Us", 70);
                  }}>
                  About Us
                </NavLink>
                <NavLink
                  className="footer_social_links_container"
                  to="#mission__vision"
                  onClick={() => {
                    // debugger;
                    setIsActive("mission__vision");
                    scrolldiv("mission__vision", 70);
                  }}>
                  Mission/Vision
                </NavLink>
                <NavLink
                  className="footer_social_links_container"
                  to="#distribution"
                  onClick={() => {
                    // debugger;
                    setIsActive("distribution");
                    scrolldiv("distribution", 70);
                  }}>
                  Distribution
                </NavLink>
              </div>

              <div className="sub__container1_child2">
                <NavLink
                  className="footer_social_links_container"
                  to="#logistics"
                  onClick={() => {
                    // debugger;
                    setIsActive("logistics");
                    scrolldiv("logistics", 70);
                  }}>
                  Logistics
                </NavLink>
                <NavLink
                  className="footer_social_links_container"
                  to="#consulting"
                  onClick={() => {
                    // debugger;
                    setIsActive("consulting");
                    scrolldiv("consulting", 70);
                  }}>
                  Consulting
                </NavLink>
                <NavLink
                  className="footer_social_links_container"
                  to="#contact__us"
                  onClick={() => {
                    // debugger;
                    setIsActive("contact__us");
                    scrolldiv("contact__us", 70);
                  }}>
                  Contact Us
                </NavLink>
              </div>
              {/* </div> */}
            </div>
            <div className="sub__container2">
              © 2024 PacificRim . All Rights Reserved
            </div>
          </div>

          <div className="footer_subbox2">
            <img
              src={CertifiedTokenSvg}
              alt="CetifiedToken"
              className="Cetified__Token"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
