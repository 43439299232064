import { GroupImgSvg } from "../../Assets";
import "./logistics.css";
import AOS from "aos";
import "aos/dist/aos.css";

import React, { useEffect } from "react";

export default function Logistics() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div data-aos="fade-up" className="LogisticsContainer" id="logistics">
        <div className="LogisticsLeftContainerContent">
          <h1>Logistics</h1>
          <ul>
            <li>
              Many years of experience in logistics with medical products
              (qualification)
            </li>
            <li>Cooperation with pharmaceutical wholesalers</li>
            <li>
              Modern logistics system for just-in-time delivery to pharmacies
              and specialist medical retailers.
            </li>
            <li>Assembly</li>
            <li>Overnight delivery</li>
            <li>Of course, ISO-certified</li>
          </ul>
        </div>

        <div className="LogisticsRightContainer">
          <img src={GroupImgSvg} alt="CarImage" className="imagesContainer" />
        </div>
      </div>
    </>
  );
}
