import React, { useEffect, useState } from "react";
import "./getInTouch.css";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  buttonIcon,
  mapImage,
  underLineGreen,
  userFieldImg1,
  phone_icon,
  envelope_icon,
  MessageIconSvg,
} from "../../Assets";
import { checkRates } from "../../Api/repo";
import { Error_Codes } from "../../utils/constantVariables";
import { MoonLoader } from "react-spinners";

const GetInTouch = () => {
  const [contact, setContact] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isLoader, setIsLoader] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isValidEmailErr, setIsValidEmailErr] = useState(false);

  const [nameErrors, setNameErrors] = useState(false);
  const [emailErrors, setEmailErrors] = useState(false);
  // const [phoneErrors, setPhoneErrors] = useState(false);
  const [messageErrors, setMessageErrors] = useState(false);

  const handleMapClick = () => {
    //38.765099203114616, -75.2116097865058
    const latitude = "38.765099203114616";
    const longitude = "-75.2116097865058";
    const mapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;

    window.open(mapsUrl, "_blank");
  };
  useEffect(() => {
    AOS.init();
  }, []);

  const updateData = (e) => {
    const { name, value } = e.target;
    setContact((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitForm = () => {
    if (validateForm()) {
      setIsLoader(true);
      console.log("submitForm", contact);
      checkRates(contact)
        .then(({ data }) => {
          setIsLoader(false);

          console.log("data", data);

          if (data.error_code === Error_Codes.success) {
            alert("Your Information Submitted Successfully. Thank you!");
            setContact({
              name: "",
              email: "",
              phone: "",
              message: "",
            });
            console.log(contact);
          } else alert("Some error occurred");
        })
        .catch((err) => {
          setIsLoader(false);
          console.log("error", err);
          alert("Network Error Please try again later");
        });
    }
  };

  const validateForm = () => {
    if (!contact.name.trim()) setNameErrors(true);
    else if (!contact.email.trim()) setEmailErrors(true);
    else if (isInvalidEmail(contact.email)) setIsValidEmailErr(true);
    else if (!contact.message.trim()) setMessageErrors(true);
    else return true;
    return false;
  };

  const isInvalidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log(emailRegex.test(email));
    return !emailRegex.test(email);
  };

  return (
    <>
      <div className="get__In__Touch__Container" id="contact__us">
        <div className="get__In__Touch__Form">
          <div data-aos="fade-up">
            <div className="content__div">
              <h1>GET IN TOUCH WITH PRMD</h1>
              <img
                src={underLineGreen}
                alt="UnderLineImg"
                className="UnderLineImg"
              />
            </div>
            <div className="input__container">
              <div className="left__side">
                <div className="input__field">
                  <h4 className="input__field_h4">Name</h4>
                  <div className="fafa_icon_person">
                    <img
                      src={userFieldImg1}
                      alt=""
                      className="placeholder_icons"
                    />

                    <input
                      onChange={(e) => {
                        updateData(e);

                        setNameErrors(false);
                      }}
                      value={contact.name}
                      name="name"
                      type="text"
                      placeholder="Enter Name"
                      className={
                        nameErrors === false
                          ? "input__fields_inner_error"
                          : "input__fields_inner"
                      }
                    />
                  </div>
                  {nameErrors ? (
                    <p className="error_message">Enter Your Name</p>
                  ) : null}
                </div>
                <div className="input__field">
                  <h4 className="input__field_h4">Email</h4>
                  <div className="fafa_icon_person">
                    <img
                      src={envelope_icon}
                      alt=""
                      className="placeholder_icons_envelope"
                    />

                    <input
                      onChange={(e) => {
                        updateData(e);
                        setIsValidEmailErr(false);
                        setEmailErrors(false);
                      }}
                      value={contact.email}
                      name="email"
                      type="text"
                      placeholder="Enter Email"
                      className={
                        emailErrors === false
                          ? "input__fields_inner_error"
                          : "input__fields_inner"
                      }
                    />
                  </div>
                  {emailErrors || isValidEmailErr ? (
                    <p className="error_message">
                      {isValidEmailErr
                        ? "Enter a valid Email"
                        : "Enter Your Email"}
                    </p>
                  ) : null}
                </div>
                <div className="input__field">
                  <h4 className="input__field_h4">Phone</h4>
                  <div className="fafa_icon_person">
                    <img
                      src={phone_icon}
                      alt=""
                      className="placeholder_icons"
                    />
                    <input
                      onChange={(e) => {
                        updateData(e);

                        // setPhoneErrors(false);
                      }}
                      value={contact.phone}
                      name="phone"
                      type="text"
                      placeholder="Enter Phone"
                      className="input__fields_inner"
                      // className={
                      //   phoneErrors == false
                      //     ? "input__fields_inner_error"
                      //     : "input__fields_inner"
                      // }
                      onKeyPress={(e) => {
                        const allowedCharacters = /[0-9+\b\t\r]/;
                        if (!allowedCharacters.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {/* {phoneErrors ? (
                    <p className="error_message">Enter the Phone Number</p>
                  ) : null} */}
                </div>

                <div className="input__field">
                  <h4 className="input__field_h4">Message</h4>
                  <div className="fafa_icon_person_textarea">
                    <img
                      src={envelope_icon}
                      alt=""
                      className="placeholder_icons_envelope"
                    />

                    <textarea
                      style={{
                        resize: "none",
                      }}
                      onChange={(e) => {
                        updateData(e);

                        setMessageErrors(false);
                      }}
                      value={contact.message}
                      name="message"
                      type="text"
                      placeholder=" Write Your Message"
                      // className="input__fields_inner_massage"
                      className={
                        messageErrors === false
                          ? "input__fields_inner_massage_error"
                          : "input__fields_inner_massage"
                      }
                    />
                  </div>
                  {messageErrors ? (
                    <p className="error_message">Enter the Message</p>
                  ) : null}
                </div>
                <div className="sendButton">
                  <button
                    disabled={isLoader}
                    onClick={submitForm}
                    className="getInTouchButtonSend">
                    {isLoader ? (
                      <MoonLoader size={20} color={"#fff"} loading={true} />
                    ) : (
                      <>
                        <div className="join__button">Send </div>
                        <div className="getInTouchButtonIcon">
                          <img src={buttonIcon} alt="arrow" />
                        </div>
                      </>
                    )}
                  </button>
                </div>
              </div>

              <div className="right__side">
                <div className="Contact__email">
                  <div className="main_email">
                    <div className="icons">
                      <img src={MessageIconSvg} alt="" />
                    </div>
                    <div className="iconsText">
                      <h3 className="Contact__email_h3">
                        info@pacificrim-medical.com
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="map__image" onClick={handleMapClick}>
                  <img src={mapImage} alt="mapImage" className="mapImage" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetInTouch;
