import React, { useState, useEffect } from "react";

import { buttonIcon, stayHealthy } from "../../Assets";
import "./AboutUs.css";

import AOS from "aos";
import "aos/dist/aos.css";

export default function AboutUs() {
  const handleGetInTouch = () => {
    const getInTouchSection = document.getElementById("contact__us");
    if (getInTouchSection) {
      const topOffset = getInTouchSection.offsetTop - 90;
      window.scrollTo({
        top: topOffset,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div data-aos="fade-up" className="aboutUsContainer" id="about__Us">
        <div className="leftContainer">
          <img
            src={stayHealthy}
            alt="stayHealthyImage"
            className="StayHealthyImgContainer"
          />
        </div>
        <div className="rightContainer">
          <div className="rightContainerContent">
            <h3 className="sub_heading">ABOUT PACIFIC RIM</h3>
            <h1 className="heading">INT. MEDICAL MARKETS</h1>
            <p className="paragraph">
              PACIFIC RIM MEDICAL DIAGNOSTICS - an experienced technology
              transfer company that brings innovations and products from
              development laboratories to pharmacists, authorities, governments
              and into specialist medical shops and laboratories. With our
              global contacts to leading research laboratories and medical
              companies, we find new, high-tech products. To a certain extent,
              for example, we "inject" new diagnostic and medical products into
              the markets through pharmacists as partners.
            </p>

            <button className="getInTouchButton" onClick={handleGetInTouch}>
              Get In Touch{" "}
              <div>
                <img
                  src={buttonIcon}
                  alt="arrow"
                  className="getInTouchButtonIconAboutUs"
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
