import React, { useState, useEffect } from "react";
import {} from "../../Assets";
import "./missionvision.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function MissionVision() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div
      className="missionVisionContainer"
      id="mission__vision"
      data-aos="fade-up">
      <div className="VisionContainerLeft">
        <div className="VisionContainerContent">
          <h3>OUR MISSION</h3>
          <h1>TOP QUALITY PRODUCTS FROM THE BEST PARTNERS</h1>
          <div className="missionVisionParagraph">
            <p>
              We place the highest demands on our products. Therefore, in terms
              of quality, they meet the tough German / European standards and
              certifications - such as CE mark, DIN EN ISO 13485: 2003 - and
              they are approved by the US FDA, Canada Health Sciences or the
              Australian Health Authority. All of our products naturally comply
              with the European directive for in-vitro diagnostics (IVDD 98/79 /
              EC). Leading laboratories and research facilities in the field of
              diagnostics in Europe, North America or Australia validate the
              fulfilment of the very high-quality standards. <br />
              <br />
              <br />
              We have exclusive partnerships with developers / manufacturers who
              specialize in diagnostics. They develop innovative, high-quality
              products for us that are used by patients via pharmacies or via
              "Point of Care". In the interests of the patients, in addition to
              extreme precision, we also demand that the products are very easy
              to use in everyday use. <br />
              <br />
              <br /> From our many years of experience in the development,
              production and marketing of high quality pregnancy, ovulation
              tests, diabetes tests, allergy tests and tests for autoimmune or
              infectious diseases such as influenza, chlamydia, Strep A and H.
              pylori, we know that our partners, the pharmacies or the medical
              specialist trade, have the necessary knowledge and enjoy the trust
              of their customers. In order to further strengthen pharmacies as
              proactive health centers, we are developing innovative products
              with which they can distinguish themselves even more strongly with
              customers, and in some cases also through professional advice, as
              an integral part of a health system that is in transition. We have
              launched special marketing programs that include B. make it easier
              for pharmacies to acquire new, to offer innovative services. We
              also provide these pro-active "health centers" with information
              and materials for medical education.
            </p>
          </div>
        </div>
      </div>
      <div className="VisionContainerRight">
        <div className="VisionContainerContent">
          <h3>OUR VISION</h3>
          <h1>QUALITY OF LIFE THROUGH INNOVATIVE PRODUCTS</h1>
          <div className="missionVisionParagraph">
            <p>
              The advances in medical technology in the leading research centers
              and the possibility of individual diagnostics allow patients and
              doctors to react much faster and more effectively. Thanks to our
              long-term access to research laboratories and leading research and
              development centers, PRMD is a reliable partner for the
              distribution and marketing of diagnostic procedures and OTC
              products. <br /> <br />
              <br /> We think that with our products and processes we can help
              to make precise diagnostic statements about hormonal processes in
              the body or to recognize more quickly whether diseases are
              present. In this way, the chances of recovery can be improved and
              the quality of life of patients can be positively influenced.{" "}
              <br />
              <br />
              <br /> PRMD will continue to use the partnerships and contacts
              intensively in the future in order to bring further novel products
              from the rapid test area as well as further diagnostic products or
              services to the market. <br />
              <br />
              <br /> We see ourselves as a partner to pharmacies, doctors and
              medical and pharmaceutical wholesalers. If we manage to make a
              positive contribution to the quality of life with our products and
              at the same time to relieve the health system, then we are
              satisfied with our work.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
