import React, { useState, useEffect } from "react";
import "./appNavbar.css";
import { NavLink } from "react-router-dom";
import { FiAlignRight, FiX } from "react-icons/fi";
import Fade from "react-reveal/Fade";
import { HeadphoneLogo, PacificrimLogo } from "../../Assets";
import { TfiEmail } from "react-icons/tfi";

function Navbar(props) {
  const [isScrolling, setIsScrolling] = useState(false);
  const [isActive, setIsActive] = useState("home");
  const [toggleMenu, setToggleMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsScrolling(true);
        setToggleMenu(false);
      } else if (window.scrollY === 0) {
        setIsScrolling(false);
      }
    });
  });
  //-------
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sectionOffsets = {
        home: document.getElementById("home").offsetTop,
        about__Us: document.getElementById("about__Us").offsetTop,
        mission__vision: document.getElementById("mission__vision").offsetTop,
        distribution: document.getElementById("distribution").offsetTop,
        logistics: document.getElementById("logistics").offsetTop,
        consulting: document.getElementById("consulting").offsetTop,
        contact__us: document.getElementById("contact__us").offsetTop,
      };
      let activeSection = "";

      Object.entries(sectionOffsets).forEach(([sectionId, offset]) => {
        if (scrollPosition >= offset - window.innerHeight / 3) {
          activeSection = sectionId;
        }
      });

      setIsActive(activeSection);
      setIsScrolling(scrollPosition > 0);

      setToggleMenu(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  function scrolldiv(scroll, offset) {
    var elem = document.getElementById(scroll);
    if (elem) {
      window.scrollTo({
        top: elem.offsetTop - offset,
        behavior: "smooth",
      });
    }
  }

  window.onload = function () {
    var isRefreshed =
      performance &&
      performance.navigation &&
      performance.navigation.type === performance.navigation.TYPE_RELOAD;

    if (isRefreshed) {
      scrolldiv("home", 0);
    } else {
      scrolldiv("home", 0);
      // setIsActive("home");
    }
  };

  return (
    <Fade top>
      <nav
        className={
          isScrolling
            ? "NavBar_Main_Container Active_Header"
            : "NavBar_Main_Container"
        }>
        <div className="logoContainer">
          <img
            src={PacificrimLogo}
            alt=""
            className="Nav_Image_Style"
            onClick={() => {
              // setIsActive("home");
              scrolldiv("home", 0);
            }}
          />
        </div>

        <div>
          <div className={"Navlink_Wrapper"}>
            <NavLink
              to="#home"
              onClick={() => {
                scrolldiv("home", 0);
              }}
              activeStyle={{
                color: isActive === "home" ? " #266A86" : "",
              }}
              className={
                isActive === "home" ? "Link_Style_Scroll" : "Link_Style"
              }
              style={({ isActive }) => {
                return {
                  backgroundImage: isActive ? "background-color: #266A86" : "",
                  color: isActive ? "266A86" : "",
                  border: "none",
                  borderRadius: "60px",
                };
              }}>
              Home
            </NavLink>

            <NavLink
              to="#about__Us"
              activeStyle={{
                color: isActive === "aboutUs" ? " #266A86" : "",
              }}
              className={
                isActive === "about__Us" ? "Link_Style_Scroll" : "Link_Style"
              }
              onClick={() => {
                // setIsActive("about__Us");
                scrolldiv("about__Us", 90);
              }}
              style={({ isActive }) => {
                return {
                  backgroundImage: isActive ? "background-color: #266A86" : "",
                  color: isActive ? "266A86" : "",
                  border: "none",
                  borderRadius: "60px",
                };
              }}>
              About Us
            </NavLink>

            <NavLink
              to="#mission__vision"
              activeStyle={{
                color: isActive === "mission__vision" ? " #266A86" : "",
              }}
              className={
                isActive === "mission__vision"
                  ? "Link_Style_Scroll"
                  : "Link_Style"
              }
              onClick={() => {
                // setIsActive("mission__vision");
                scrolldiv("mission__vision", 90);
              }}
              style={({ isActive }) => {
                return {
                  backgroundImage: isActive ? "background-color: #266A86" : "",
                  color: isActive ? "266A86" : "",
                  border: "none",
                  borderRadius: "60px",
                };
              }}>
              Mission/Vision
            </NavLink>

            <NavLink
              to="#distribution"
              activeStyle={{
                color: isActive === "distribution" ? " #266A86" : "",
              }}
              className={
                isActive === "distribution" ? "Link_Style_Scroll" : "Link_Style"
              }
              onClick={() => {
                // setIsActive("distribution");
                scrolldiv("distribution", 90);
              }}
              style={({ isActive }) => {
                return {
                  backgroundImage: isActive ? "background-color: #266A86" : "",
                  color: isActive ? "266A86" : "",
                  border: "none",
                  borderRadius: "60px",
                };
              }}>
              Distribution
            </NavLink>
            <NavLink
              to="#logistics"
              activeStyle={{
                color: isActive === "logistics" ? "#266A86" : "",
              }}
              className={
                isActive === "logistics" ? "Link_Style_Scroll" : "Link_Style"
              }
              onClick={() => {
                // setIsActive("logistics");
                scrolldiv("logistics", 90); // Adjust the offset as per your requirement
              }}
              style={({ isActive }) => {
                return {
                  backgroundImage: isActive ? "background-color: #266A86" : "",
                  color: isActive ? "266A86" : "black",
                  border: "none",
                  borderRadius: "60px",
                };
              }}>
              Logistics
            </NavLink>
            <NavLink
              to="#consulting"
              activeStyle={{
                color: isActive === "consulting" ? "#266A86" : "",
              }}
              className={
                isActive === "consulting" ? "Link_Style_Scroll" : "Link_Style"
              }
              onClick={() => {
                // setIsActive("consulting");
                scrolldiv("consulting", 90);
              }}
              style={({ isActive }) => ({
                backgroundImage:
                  isActive === "consulting" ? "background-color: #266A86" : "",
                // color: isActive ? "#266A86" : "",
                border: "none",
                borderRadius: "60px",
              })}>
              Consulting
            </NavLink>

            <NavLink
              to="#contact__us"
              activeStyle={{
                color: isActive === "contact__us" ? "#266A86" : "",
              }}
              className={
                isActive === "contact__us" ? "Link_Style_Scroll" : "Link_Style"
              }
              onClick={() => {
                // setIsActive("contact__us");
                scrolldiv("contact__us", 90);
              }}
              style={({ isActive }) => {
                return {
                  backgroundImage: isActive ? "background-color: #266A86" : "",
                  color: isActive ? "266A86" : "",
                  border: "none",
                  borderRadius: "60px",
                };
              }}>
              Contact Us
            </NavLink>
          </div>
        </div>
        <div className="NavbarButtonContainer">
          <button
            className="navbarConTactButton"
            onClick={() => {
              // setIsActive("contact__us");
              scrolldiv("contact__us", 90);
            }}>
            <div className="navbarConTactButtonIcon">
              {" "}
              <TfiEmail className="navbarConTactButtonIcon" />
            </div>
            info@pacificrim-medical.com{" "}
          </button>
        </div>

        <div className="collapse_button_number">
          <div className="NavbarButtonContainer small_screen">
            <button
              className="navbarConTactButton"
              onClick={() => {
                // setIsActive("contact__us");
                scrolldiv("contact__us", 100);
              }}>
              <div className="navbarConTactButtonIcon">
                {" "}
                <TfiEmail className="navbarConTactButtonIcon" />
              </div>
              info@pacificrim-medical.com{" "}
            </button>
          </div>
          <div className={"NavLink_App_Smaller_Devices"}>
            {toggleMenu ? (
              <div className="Toggle_Btn_CLose">
                <FiX
                  color="black"
                  size={30}
                  onClick={() => setToggleMenu(false)}
                />
              </div>
            ) : (
              <div className="Toggle_Btn_Open">
                <FiAlignRight
                  color="black"
                  size={30}
                  onClick={() => setToggleMenu(true)}
                />
              </div>
            )}

            {toggleMenu ? (
              <div className={"NavLink_App_Smaller_Overlay"}>
                <div className={"Navlink_App_Links_Wrapper_SmallScreen_links"}>
                  <NavLink
                    to="#home"
                    onClick={() => {
                      // setIsActive("home");
                      scrolldiv("home", 0);
                    }}
                    activeStyle={{
                      color: isActive === "home" ? " #266A86" : "",
                    }}
                    className={
                      isActive === "home" ? "Link_Style_Scroll" : "Link_Style"
                    }
                    style={({ isActive }) => {
                      return {
                        backgroundImage: isActive
                          ? "background-color: #266A86"
                          : "",
                        color: isActive ? "266A86" : "",
                        border: "none",
                        borderRadius: "60px",
                      };
                    }}>
                    Home
                  </NavLink>

                  <NavLink
                    to="#aboutUs"
                    activeStyle={{
                      color: isActive === "aboutUs" ? " #266A86" : "",
                    }}
                    className={
                      isActive === "about__Us"
                        ? "Link_Style_Scroll"
                        : "Link_Style"
                    }
                    onClick={() => {
                      // setIsActive("about__Us");
                      if (window.innerWidth > 768 && window.innerWidth < 1025) {
                        scrolldiv("about__Us", 100);
                      } else {
                        scrolldiv("about__Us", 70);
                      }
                    }}
                    style={({ isActive }) => {
                      return {
                        backgroundImage: isActive
                          ? "background-color: #266A86"
                          : "",
                        color: isActive ? "266A86" : "",
                        border: "none",
                        borderRadius: "60px",
                      };
                    }}>
                    About Us
                  </NavLink>

                  <NavLink
                    to="#mission__vision"
                    activeStyle={{
                      color: isActive === "mission__vision" ? " #266A86" : "",
                    }}
                    className={
                      isActive === "mission__vision"
                        ? "Link_Style_Scroll"
                        : "Link_Style"
                    }
                    onClick={() => {
                      // setIsActive("mission__vision");
                      if (window.innerWidth > 768 && window.innerWidth < 1025) {
                        scrolldiv("mission__vision", 100);
                      } else {
                        scrolldiv("mission__vision", 70);
                      }
                    }}
                    style={({ isActive }) => {
                      return {
                        backgroundImage: isActive
                          ? "background-color: #266A86"
                          : "",
                        color: isActive ? "266A86" : "",
                        border: "none",
                        borderRadius: "60px",
                      };
                    }}>
                    Mission/Vision
                  </NavLink>

                  <NavLink
                    to="#distribution"
                    activeStyle={{
                      color: isActive === "distribution" ? " #266A86" : "",
                    }}
                    className={
                      isActive === "distribution"
                        ? "Link_Style_Scroll"
                        : "Link_Style"
                    }
                    onClick={() => {
                      // setIsActive("distribution");
                      if (window.innerWidth > 768 && window.innerWidth < 1025) {
                        scrolldiv("distribution", 100);
                      } else {
                        scrolldiv("distribution", 70);
                      }
                    }}
                    style={({ isActive }) => {
                      return {
                        backgroundImage: isActive
                          ? "background-color: #266A86"
                          : "",
                        color: isActive ? "266A86" : "",
                        border: "none",
                        borderRadius: "60px",
                      };
                    }}>
                    Distribution
                  </NavLink>
                  <NavLink
                    to="#logistics"
                    activeStyle={{
                      color: isActive === "logistics" ? "#266A86" : "",
                    }}
                    className={
                      isActive === "logistics"
                        ? "Link_Style_Scroll"
                        : "Link_Style"
                    }
                    onClick={() => {
                      // setIsActive("logistics");
                      if (window.innerWidth > 768 && window.innerWidth < 1025) {
                        scrolldiv("logistics", 100);
                      } else {
                        scrolldiv("logistics", 70);
                      }
                    }}
                    style={({ isActive }) => {
                      return {
                        backgroundImage: isActive
                          ? "background-color: #266A86"
                          : "",
                        color: isActive ? "266A86" : "black",
                        border: "none",
                        borderRadius: "60px",
                      };
                    }}>
                    Logistics
                  </NavLink>
                  <NavLink
                    to="#consulting"
                    activeStyle={{
                      color: isActive === "consulting" ? "#266A86" : "",
                    }}
                    className={
                      isActive === "consulting"
                        ? "Link_Style_Scroll"
                        : "Link_Style"
                    }
                    onClick={() => {
                      // setIsActive("consulting");
                      if (window.innerWidth > 768 && window.innerWidth < 1025) {
                        scrolldiv("consulting", 100);
                      } else {
                        scrolldiv("consulting", 70);
                      }
                    }}
                    style={({ isActive }) => ({
                      backgroundImage:
                        isActive === "consulting"
                          ? "background-color: #266A86"
                          : "",
                      // color: isActive ? "#266A86" : "",
                      border: "none",
                      borderRadius: "60px",
                    })}>
                    Consulting
                  </NavLink>

                  <NavLink
                    to="#contact__us"
                    activeStyle={{
                      color: isActive === "contact__us" ? "#266A86" : "",
                    }}
                    className={
                      isActive === "contact__us"
                        ? "Link_Style_Scroll"
                        : "Link_Style"
                    }
                    onClick={() => {
                      // setIsActive("contact__us");
                      if (window.innerWidth > 768 && window.innerWidth < 1025) {
                        scrolldiv("contact__us", 100);
                      } else {
                        scrolldiv("contact__us", 70);
                      }
                    }}
                    style={({ isActive }) => {
                      return {
                        backgroundImage: isActive
                          ? "background-color: #266A86"
                          : "",
                        color: isActive ? "266A86" : "",
                        border: "none",
                        borderRadius: "60px",
                      };
                    }}>
                    Contact Us
                  </NavLink>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </nav>
    </Fade>
  );
}

export default Navbar;
