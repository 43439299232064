import React, { useState, useEffect } from "react";

import { lab, line, microscope, microscope2 } from "../../Assets";
import "./aboutUsBottom.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function AboutUsBottom() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="innerContainer" data-aos="fade-up">
      {/* <img src={line} alt="" className="dottedLine" /> */}
      <div className="diagnosticHomeTestText">
        <h3>ABOUT PACIFIC RIM</h3>
        <h1>DIAGNOSTIC HOME TESTS - HIGH QUALITY OF LIFE</h1>
      </div>
      <div className="leftRightParent">
        <div className="containerLeft">
          <div className="containerContent">
            <h3>Leading Manufacturer Partnership</h3>
            <p>
              For more than 5 years, the management of PRMD has been working
              closely with leading manufacturers of rapid diagnostic
              preparations worldwide. Our high-quality point-of-care diagnostics
              (POC) are successfully used in the home, in the laboratory as well
              as in the medical and clinical environment. The quality diagnostic
              products are among the leading and most reliable in their
              respective segments.
            </p>
            <img
              src={microscope}
              alt="micropscopeimg"
              className="aboutUsBottomImagesLeft"
            />
            <h3 className="second__Heading">
              Accessible Through Trusted Channels
            </h3>
            <p>
              Our Over-The-Counter (0TC) medical products for home diagnostics
              are exclusively available through pharmacies, while our
              "professional" POC tests designed for hospitals, practices, or
              laboratories are accessible via trusted medical retailers. These
              products not only meet but exceed the highest standards in quality
              and user-friendliness, ensuring seamless access for end customers,
              doctors, and laboratories alike.
            </p>
          </div>
        </div>
        <div className="middle__container">
          <img src={line} alt="" className="dottedLine" />
        </div>
        <div className="containerRight">
          <div className="containerContent_2">
            <img
              src={microscope2}
              alt="microscope2img"
              className="aboutUsBottomImagesRight"
            />
            <h3>Innovation and Product Development</h3>
            <p>
              PRMD remains at the forefront of innovation, constantly scouting
              for new methods and preparations globally. Leveraging extensive
              expertise in product development and business expansion, we uphold
              a reputation for delivering products of unparalleled quality,
              swift market entry, exceptional service, and customer
              satisfaction.
            </p>
            <img
              src={lab}
              alt="labimg"
              className="aboutUsBottomImagesRight secondImage"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
