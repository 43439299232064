import React from "react";
import "./home.css"; // You can create and import home.css if needed.
import Header from "../header/Header";
import AboutUs from "../AboutUs/AboutUs.lazy";
import AboutUsBottom from "../AboutUsBottom/AboutUsBottom.lazy";
import MissionVision from "../MissionVission/MissionVision.lazy";
import Distribution from "../Distribution/Distribution";
import Logistics from "../Logistics/Logistics";
import Consulting from "../Consulting/Consulting";
import GetInTouch from "../GetInTouch/GetInTouch";

const Home = () => {
  return (
    <>
      <Header />
      <AboutUs />
      <AboutUsBottom />
      <MissionVision />
      <Distribution />
      <Logistics />
      <Consulting />
      <GetInTouch />
    </>
  );
};

export default Home;
