import React, { useEffect } from "react";
import "./distribution.css";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  BottomInjection,
  DistributionDiamondImg,
  TopInjection,
  UnderLineImg,
  greenLayer,
  learnMoreButton,
} from "../../Assets";

const Distribution = () => {
  const handleGetInTouch = () => {
    const getInTouchSection = document.getElementById("contact__us");
    if (getInTouchSection) {
      const topOffset = getInTouchSection.offsetTop - 90;
      window.scrollTo({
        top: topOffset,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="DistributionContainer" id="distribution">
        {/* <img src={TopInjection} alt="TopInjection" className="TopInjection" />
        <img
          src={BottomInjection}
          alt="BottomInjection"
          className="BottomInjection"
        /> */}

        <div data-aos="fade-up">
          <div className="MainHeading">
            <div>
              <h1>International Market Access</h1>
            </div>
            <div>
              <img
                src={UnderLineImg}
                alt="UnderLineImg"
                className="UnderLineImgDis"
              />
            </div>
          </div>

          <div className="DistributionContentContainer">
            <div className="DistributionRightContainer">
              <h3>Competence on site</h3>
              <p className="DistributionRightContainerPara">
                In the competent employees of our nationwide OTC pharmaceutical
                sales team, pharmacists will find competent, highly professional
                partners who have in-depth knowledge of our products and the
                market.
              </p>
              <p className="DistributionRightContainerPara">
                They advise and train the pharmaceutical staff on site and offer
                support for presentations and information for end users.
              </p>

              <p className="DistributionRightContainerPara">
                The goods ordered from the ISO-certified logistics center reach
                the health centers, laboratories or point of care within 24
                hours.
              </p>
            </div>
            <div className="DistributionMiddleContainer">
              <img
                src={DistributionDiamondImg}
                alt="DistributionDiamondImg"
                className="DistributionDiamondImg"
              />
            </div>
            <div className="DistributionLeftContainer">
              <h3>Global Distribution</h3>
              <p className="DistributionRightContainerPara">
                PRMD is active in various key markets, such as Canada,
                Australia, Europe, and Asia. In addition to our own
                subsidiaries, we work together with leading partners in all
                markets in order to be able to offer the best service in terms
                of support, advice, sales and logistics. Due to the quality of
                the PRMD products and the innovative marketing strategies, our
                products dominate the product segments in various international
                markets.
              </p>

              <button
                className="DistributionContactUsButton"
                onClick={handleGetInTouch}>
                Get In Touch
                <div className="DistributionContactUsIcon">
                  <img src={learnMoreButton} alt="arrow" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Distribution;
