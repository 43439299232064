import React, { useState, useEffect } from "react";
import "./header.css";
import { HomePageImage, greenLayer, learnMoreButton } from "../../Assets";
import { MoonLoader } from "react-spinners";
import AOS from "aos";
import "aos/dist/aos.css";

const Header = () => {
  let [imagesLoaded, setImagesLoaded] = useState(false);
  let [greenLayerLoaded, setGreenLayerLoaded] = useState(false);
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease",
      delay: 100,
    });
  }, []);

  const handleImageLoad = () => {
    setImagesLoaded(true);
    setGreenLayerLoaded(true);
  };

  const handleGetInTouch = () => {
    const getInTouchSection = document.getElementById("contact__us");
    if (getInTouchSection) {
      const topOffset = getInTouchSection.offsetTop - 90;
      window.scrollTo({
        top: topOffset,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="headerContainer" id="home">
      <div
        className="loaderContainer"
        style={{
          display: imagesLoaded && greenLayerLoaded ? "none" : "flex",
        }}>
        <MoonLoader color={"black"} size={50} />
      </div>
      <img
        src={HomePageImage}
        alt="homePageLogo"
        className="homePageImage"
        style={{ display: imagesLoaded ? "block" : "none" }}
        // onLoad={handleImageLoad}
        onLoad={() => setImagesLoaded(true)}
      />
      <img
        src={greenLayer}
        alt="homePageLogo"
        className="greenLayer"
        style={{ display: greenLayerLoaded ? "block" : "none" }}
        // onLoad={handleImageLoad}
        onLoad={() => setGreenLayerLoaded(true)}
      />
      {imagesLoaded && greenLayerLoaded && (
        <div className="contentContainer" data-aos="fade-up">
          <div className="welcomeText">
            {" "}
            <h4 className="welcome__heading">WELCOME TO PACIFICRIM</h4>
          </div>
          <div className="descriptionText">
            BRIDGING INNOVATIONS FROM LABS TO PATIENTS WITH PRMD
          </div>
          <div className="learnMoreDiv">
            <button className="learnMoreButton" onClick={handleGetInTouch}>
              Learn More{" "}
              <div>
                <img
                  src={learnMoreButton}
                  alt="arrow"
                  className="learnMoreButtonIcon"
                />
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
