import React, { useEffect } from "react";
import "./consulting.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { ConsultingBG, ConsultingRightImage } from "../../Assets";

const Consulting = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="ConsultingContainer" id="consulting">
      <div data-aos="fade-up" className="ConsultingContainerSub">
        <div className="ConsultingLeftContainer">
          <img
            src={ConsultingRightImage}
            alt="ConsultingRightImage"
            className="ConsultingRightImage"
          />
        </div>
        <div className="ConsultingRightContainer">
          <h3 className="consult_h3">CONSULTING</h3>
          <h1 className="consult_h1">VISIONARIES, STRATEGIES, ENTREPRENEURS</h1>
          <p>
            {" "}
            With decades of successful competence in all areas of strategic
            business and product development, production and international
            marketing, PRMD supports pharmaceutical and diagnostic companies
            that are also active in the area of gene expression profiling,
            differential diagnostics and laboratory diagnostics.
          </p>
          <p className="consult_p">
            Due to the extensive contacts in diagnostics and in various
            pharmaceutical areas on both sides of the Atlantic, PRMD now sees
            itself as a "Technology Clearing House". Thanks to our in-depth
            knowledge of the market in processes and technologies, licensing in
            Europe and the USA and in the area of sales, we support companies in
            the development and implementation of future-oriented strategies. As
            experienced management consultants, we see ourselves as consular
            partners for our clients. We support top management in strategic
            decisions: in innovation management, the implementation of market
            and expansion strategies and the development and evaluation of
            portfolio strategies.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Consulting;
